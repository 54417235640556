import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
  }
`;

export function Exclamation(props) {
  return (
    <Container {...props}>
      <svg
        width="13px"
        height="40px"
        viewBox="0 0 13 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M13,33.75 C13,37.19625 10.0841,40 6.5,40 C2.9159,40 0,37.19625 0,33.75 C0,30.30375 2.9159,27.5 6.5,27.5 C10.0841,27.5 13,30.30375 13,33.75 Z M0.752375,1.96867188 L1.857375,23.2186719 C1.90929375,24.2165625 2.76583125,25 3.8049375,25 L9.1950625,25 C10.2341688,25 11.0907063,24.2165625 11.142625,23.2186719 L12.247625,1.96867188 C12.3032813,0.89765625 11.4153,0 10.3000625,0 L2.6999375,0 C1.5847,0 0.69671875,0.89765625 0.752375,1.96867188 Z"
            fill={props.color}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </Container>
  );
}

Exclamation.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Exclamation.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
