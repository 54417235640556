export const RESET = 'RESET';
export const RESET_FORM = 'RESET_FORM';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_BANNER = 'OPEN_BANNER';
export const CLOSE_BANNER = 'CLOSE_BANNER';
export const SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS';
export const SAVE_USER_DATA_FAILD = 'SAVE_USER_DATA_FAILD';
export const VALIDATE_ZIP_CODE_SUCCESS = 'VALIDATE_ZIP_CODE_SUCCESS';
export const VALIDATE_ZIP_CODE_FAILD = 'VALIDATE_ZIP_CODE_FAILD';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_FAILD = 'CONTACT_FAILD';