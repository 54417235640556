import styled from 'styled-components';
import { media } from '../../utils/media';

export const Container = styled.div`
  margin: 0 0 10vh 0;
  display: flex;
  ${media.phone`flex-direction: column`};
  ${media.phone`${({ right }) => right && 'flex-direction: column-reverse'}`};
  p {
    font-weight: 300;
    color: var(--color4);
  }

  .image-subtitle {
    max-width: 55%;
    text-align: center;
    font-size: 13px;
    color: var(--color11);
  }
`;

export const TextContainer = styled.div`
  margin: 5px 40px;
  ${media.phone`margin: 5px`};
`;

export const Title = styled.h1`
  color: var(--color4);
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 45%;
  img {
    height: 150px;
    width: auto;
  }
`;
