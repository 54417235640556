import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    width: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    height: auto;
  }
`;

export function Email(props) {
  return (
    <Container {...props}>
      <svg fill="none" viewBox="0 0 31 22" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.8929 1.1001H1.10718V20.9001H29.8929V1.1001Z"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.10718 1.1001L13.2857 11.6601C14.6143 12.7601 16.3858 12.7601 17.7143 11.6601L29.8929 1.1001"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8927 20.9002L18.3784 11.2202"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.10718 20.9002L12.4 11.2202"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
}

Email.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Email.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
