import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyrightGroup, CopyrightText } from './styles';
import LanguageChooser, { supportedLanguages } from '../LangChooser';

export default function Copyright() {
  const { i18n, t } = useTranslation();
  const today = new Date();
  const year = today.getFullYear();

  const [ lang, setLang ] = useState(i18n.language);

  const changeLanguage = (e) => {
    const language = e.target.value;
    i18n.changeLanguage(language);
    setLang(language);
    window.location.reload();
  };

  return (
    <CopyrightGroup>
      <CopyrightText>
        <span>© {year} BlurialInsurance.com. </span>
        <span className="rights-reserved">
          {t('homepage.footer.rights_reserved')}
          <LanguageChooser
            value={lang}
            languages={supportedLanguages}
            onChange={changeLanguage}
          />
        </span>
      </CopyrightText>
      <img src={require('../../images/bbb.png')} alt="BBB" />
    </CopyrightGroup>
  );
}
