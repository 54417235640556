import initialState from './initialState';
import * as types from './types';

export default (state, action) => {
  switch (action.type) {
    case types.RESET:
      return initialState;

    case types.RESET_FORM:
      return {
        ...state,
        formCompleted: false,
        customer: {
          ...initialState.customer,
        },
      };

    case types.OPEN_MODAL:
      return {
        ...state,
        app: {
          ...state.app,
          modalOpen: true,
        },
      };

    case types.CLOSE_MODAL:
      return {
        ...state,
        app: {
          ...state.app,
          modalOpen: false,
        },
      };

    case types.OPEN_BANNER:
      return {
        ...state,
        app: {
          ...state.app,
          bannerOpen: true,
        },
      };

    case types.CLOSE_BANNER:
      return {
        ...state,
        app: {
          ...state.app,
          bannerOpen: false,
        },
      };

    case types.SAVE_USER_DATA_SUCCESS:
      return {
        ...state,
        formCompleted: true,
        customer: {
          ...action.data,
        },
      };

    case types.SAVE_USER_DATA_FAILD:
      return state;

    case types.VALIDATE_ZIP_CODE_SUCCESS:
      const { city, state: customerState, zipcode } = action.data;

      return {
        ...state,
        customer: {
          ...state.customer,
          city,
          zipcode,
          state: customerState,
        },
      };

    case types.VALIDATE_ZIP_CODE_FAILD:
      return state;

    case types.CONTACT_SUCCESS:
      return {
        ...state,
        contactForm: {
          ...state.action.data,
        },
      };

    case types.CONTACT_FAILD:
      return state;

    default:
      return initialState;
  }
};
