import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from './context';

import App from './App';
import './utils/i18n';

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById('root'),
);
