import React, { createContext, useReducer } from 'react';
import initialState from './initialState';
import reducer from './reducer';
import connect from './withContext';

const Context = createContext();
const Consumer = Context.Consumer;

function Provider({ children }) {
  let [ state, dispatch ] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export { Context, Provider, Consumer, connect };
