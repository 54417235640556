import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title, DropDown } from './styles';

export default function Input(props) {
  const { t: translate } = useTranslation();
  const value = translate(`form.${props.name}`);
  return (
    <label>
      <Title required={props.required}>{value}</Title>
      <DropDown {...props} isEmpty={!props.value}>
        <option value="" disabled="disabled" hidden="hidden">
          {value}
        </option>
        {props.options.map((option, idx) => {
          return (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </DropDown>
    </label>
  );
}
