import styled from 'styled-components';
import { media } from '../../utils/media';

export const Container = styled.div`
  padding: 20px 23%;
  ${media.phone`padding: 20px`};
  ${media.tablet`padding: 20px`};
`

export const Title = styled.h1`
  color: var(--color1);
  font-weight: 500;
  font-size: 45px;
  ${media.phone`font-size: 35px`};
  ${media.tablet`text-align: center`};
  ${media.tablet`font-size: 40px;`};
`;