import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Context } from './context';

import Home from './pages/Home';
import Form from './pages/Form';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import NotFound from './pages/404';
import Partners from './pages/Partners';
import ThankYou from './pages/ThankYou';
import { Loader } from './components/Loader';

export default () => {
  const { state } = useContext(Context);

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/form" component={Form} />
          <Route path="/terms" component={Terms} />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/partners" component={Partners} />
          {state.formCompleted && <Route path="/thank-you" component={ThankYou} />}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
};
