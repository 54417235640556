import { Link as RRLink } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../utils/media';

export const Header = styled.div`
  background-color: var(--color4);
  color: var(--color13);
  display: flex;
  flex-direction: row;
  ${media.phone`flex-direction: column;`} align-items: center;
  padding: 3.7% 0 3.5% 15%;
  ${media.phone`padding: 0;`};
  ${media.tablet`padding: 20px 10px;`};
`;

export const Right = styled.div`
  max-width: 45%;
  height: 260px;
  ${media.phone`
    max-width: 100%;
    height: auto;
    margin: 0 20px 40px 20px;
  `} 
  ${media.tablet`
    max-width: 100%;
    height: auto;
  `}
  padding-left: 3.5%;
  display: flex;
  flex-direction: column;
`;

export const Left = styled.div`
  ${media.phone`
    & a {
      display: flex;
      justify-content: center;
    }
    img {
      margin: 20px;
      width: 85%;
      height: auto;
    }
  `};
`;

export const StyledDeclaimer = styled.div`
  color: var(--color9);
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  a {
    color: var(--color13);
    font-weight: 500;
    text-decoration: none;
  }
  padding-top: 5%;
`;

export const FooterOption = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  max-width: 90%;
  p {
    padding-left: 15px;
    font-weight: 300;
    color: var(--color9);
  }
  svg {
    max-height: 25px;
    max-width: 25px;
  }
`;

export const OptionsContainer = styled.div`
  padding: 15px 0 0 30px;
  ${media.phone`padding: 15px 40px;`}
`;

export const Separator = styled.div`
  width: ${media.phone ? '1px' : '2px'};
  background-color: var(--color9);
  border-radius: 10px;
  margin: ${({ margin }) => margin && '0 20px;'};
  height: ${({ height }) => (height ? height : '200px')};
  ${({ shouldRotate }) => {
    return (
      shouldRotate &&
      media.phone`
      height: 1px;
      width: 90%;
      margin: 10px 0 12% 0;
    `
    );
  }};
`;

export const Link = styled(RRLink)`
  font-size: 19px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color13);
`;

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.phone`
    justify-content: space-between;
    padding: 0px 20px 0px 10px;
  `};
`;

export const CopyrightGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color12);
  margin: 0 auto;
  padding: 17px 15% 17px 15%;
  img {
    width: 105px;
    height: auto;
  }
  .rights-reserved {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
  }
  ${media.phone`
    flex-direction: column;
    justify-content: center;
    img {
      margin: 10px 0;
    }
    .rights-reserved {
      display: none;
    }
  `} ${media.tablet`padding: 5px 15px;`};
`;

export const CopyrightText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Arrow = styled.div`
  ${media.phone`display: none;`} 
  ${media.tablet`display: none;`}
  margin-left: 8%;
  svg {
    height: 35px;
    width: auto;
  }
`;
