import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    width: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
    height: auto;
  }
`;

export function Checkmark(props) {
  return (
    <Container {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18">
        <path d="M23.8852 2.69217L9.03349 17.9061C8.91101 18.0313 8.69665 18.0313 8.57416 17.9061L0.091866 9.26609C-0.030622 9.14087 -0.030622 8.92174 0.091866 8.79652L2.14354 6.66783C2.26603 6.54261 2.48038 6.54261 2.60287 6.66783L8.75789 12.96L21.3742 0.093913C21.4967 -0.0313043 21.711 -0.0313043 21.8335 0.093913L23.8852 2.1913C24.0383 2.34783 24.0383 2.53565 23.8852 2.69217Z" />
      </svg>
    </Container>
  );
}

Checkmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Checkmark.defaultProps = {
  color: '#26b6ce',
  size: 30,
};