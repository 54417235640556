import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: ${({ size }) => `${+size}px`};
    width: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
  }
`;

export function SendMail(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 48 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M42.1033 3.14159L42.5904 2.70536H41.9366H23.1641H22.4908L23.001 3.14478L32.5704 11.3867L32.7368 11.53L32.9004 11.3835L42.1033 3.14159ZM20.4378 17.7321V17.9821H20.6878H44.3306H44.5806V17.7321V4.77893V4.21947L44.1639 4.59269L33.5719 14.0777L33.7386 14.264L33.5719 14.0777C33.3385 14.2867 33.0457 14.3913 32.7527 14.3913C32.4676 14.3913 32.1822 14.2924 31.9515 14.0939C31.9514 14.0939 31.9514 14.0939 31.9514 14.0938L20.8509 4.5337L20.4378 4.17789V4.72313V17.7321ZM20.67 0.25H44.3472C45.8311 0.25 47.036 1.4492 47.036 2.91966V17.7674C47.036 19.2379 45.8311 20.4375 44.3472 20.4375H20.67C19.1867 20.4375 17.9824 19.238 17.9824 17.7676V2.91989C17.9824 1.44908 19.1867 0.25 20.67 0.25Z"
          strokeWidth="0.5"
        />
        <path
          d="M1.47768 3.20532H13.2991C13.9772 3.20532 14.5268 3.75492 14.5268 4.433C14.5268 5.11108 13.9772 5.66068 13.2991 5.66068H1.47768C0.799598 5.66068 0.25 5.11108 0.25 4.433C0.25 3.75492 0.799598 3.20532 1.47768 3.20532Z"
          strokeWidth="0.5"
        />
        <path
          d="M5.9103 9.11609H13.2987C13.9768 9.11609 14.5264 9.66569 14.5264 10.3438C14.5264 11.0218 13.9768 11.5714 13.2987 11.5714H5.9103C5.23222 11.5714 4.68262 11.0218 4.68262 10.3438C4.68262 9.66569 5.23222 9.11609 5.9103 9.11609Z"
          strokeWidth="0.5"
        />
        <path
          d="M10.3439 15.0267H13.2992C13.9773 15.0267 14.5269 15.5763 14.5269 16.2544C14.5269 16.9325 13.9773 17.4821 13.2992 17.4821H10.3439C9.66581 17.4821 9.11621 16.9325 9.11621 16.2544C9.11621 15.5763 9.66581 15.0267 10.3439 15.0267Z"
          strokeWidth="0.5"
        />
      </svg>
    </Container>
  );
}

SendMail.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

SendMail.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
