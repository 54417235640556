import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Input as StyledInput } from './styles';

export default function Input(props) {
  const { t } = useTranslation();
  const title = t(`form.${props.name}`);

  return (
    <label>
      <Title required={props.required}>{title}</Title>
      <StyledInput
        {...props}
        maxLength={props.zipcode && 5}
        empty={!!props.value}
        placeholder={props.placeholder || title}
      />
    </label>
  );
}
