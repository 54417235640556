import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    width: auto;
  }
`;

export function Location(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 26 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.7386 13.7286C18.7386 10.2964 16.162 7.59961 12.8827 7.59961C9.60343 7.59961 7.02686 10.2964 7.02686 13.7286C7.26109 16.9157 9.83767 19.6125 13.1169 19.6125C16.162 19.6125 18.7386 16.9157 18.7386 13.7286Z"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8917 18.6321C24.5944 17.1611 24.8287 15.445 24.8287 13.4837C24.8287 6.61918 19.4413 0.980469 12.8827 0.980469C6.32416 0.980469 0.936768 6.61918 0.936768 13.4837C0.936768 15.1998 1.40524 16.916 1.8737 18.6321C2.10794 19.3676 2.57641 20.1031 3.04488 20.8385C4.21605 22.7998 6.08992 25.7418 8.19803 28.4385C10.0719 30.8902 11.2431 33.8321 11.9458 36.0385C12.18 36.774 13.1169 36.774 13.3512 36.0385C14.0539 33.8321 14.9908 30.8902 17.0989 28.4385C19.4413 25.4966 21.3151 22.5547 22.2521 20.8385C23.189 20.1031 23.4233 19.3676 23.8917 18.6321Z"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
}

Location.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Location.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
