import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: auto;
    width: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
  }
`;

export function Telephone(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.6918 28.4453C31.2869 27.0584 29.5331 27.0584 28.1372 28.4453C27.0724 29.5012 26.0075 30.557 24.9606 31.6308C24.6743 31.9261 24.4327 31.9887 24.0837 31.7918C23.3947 31.416 22.6609 31.1118 21.9988 30.7002C18.9117 28.7585 16.3257 26.262 14.035 23.4524C12.8986 22.0565 11.8874 20.5622 11.1805 18.88C11.0373 18.54 11.0642 18.3163 11.3416 18.0389C12.4064 17.0099 13.4444 15.9541 14.4913 14.8982C15.9499 13.4308 15.9499 11.7128 14.4824 10.2364C13.6502 9.39527 12.818 8.57206 11.9858 7.73096C11.1268 6.87196 10.2768 6.00402 9.40879 5.15397C8.00393 3.78494 6.2501 3.78494 4.8542 5.16292C3.78042 6.21877 2.75139 7.30146 1.65972 8.33942C0.648583 9.29684 0.13854 10.469 0.0311629 11.838C-0.138851 14.0661 0.406984 16.1688 1.17652 18.2179C2.75139 22.4592 5.14949 26.2263 8.05762 29.6801C11.9858 34.3509 16.6747 38.0464 22.1599 40.7129C24.6295 41.9119 27.1887 42.8335 29.9716 42.9857C31.8865 43.093 33.5508 42.6098 34.8841 41.1155C35.7968 40.0955 36.8258 39.1649 37.7922 38.1896C39.2239 36.74 39.2329 34.9862 37.8101 33.5546C36.11 31.8455 34.4009 30.1454 32.6918 28.4453Z" />
        <path d="M30.9824 21.3139L34.2842 20.7502C33.7653 17.7168 32.3336 14.9698 30.1592 12.7865C27.8595 10.4869 24.9514 9.03738 21.7479 8.58998L21.2826 11.9097C23.7613 12.2586 26.0162 13.3771 27.7969 15.1577C29.4791 16.8399 30.5797 18.9695 30.9824 21.3139Z" />
        <path d="M36.1457 6.96146C32.3338 3.14966 27.5108 0.742675 22.1867 0L21.7214 3.31967C26.3207 3.96392 30.4905 6.04878 33.7834 9.33265C36.9063 12.4555 38.9555 16.4015 39.6981 20.7412L43 20.1775C42.132 15.1488 39.7608 10.5854 36.1457 6.96146Z" />
      </svg>
    </Container>
  );
}

Telephone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Telephone.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
