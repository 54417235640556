import { Icon } from './Icon';
export { Star } from './Star';
export { Close } from './Close';
export { Email } from './Email';
export { Arrow } from './Arrow';
export { Phone } from './Phone';
export { SendMail } from './SendMail';
export { Location } from './Location';
export { Checkmark } from './Checkmark';
export { Telephone } from './Telephone';
export { ArrowLeft } from './ArrowLeft';
export { ArrowRight } from './ArrowRight';
export { Exclamation } from './Exclamation';

export default Icon;
