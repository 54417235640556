import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../utils/media';
import Icons from '../Icons';

export const Banner = styled.div`
  ${media.phone`display: none;`} top: 0;
  z-index: 100;
  width: 100%;
  padding: 5px 0px;
  background-color: var(--color4);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${media.tablet`padding: 15px 0;`};
  p {
    text-decoration: none;
    color: white;
    font-weight: normal;
    line-height: 3px;
  }
`;

export const Button = styled(Link)`
  --border-radius: 30px;
  position: relative;
  padding: 0px 22px;
  font-size: 18px;
  font-weight: 700;
  background: transparent;
  border-radius: var(--border-radius);
  border: 2px solid var(--color3);
  outline: none;
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    border-radius: var(--border-radius);
    box-shadow: 0 10px 20px var(--colorE);
    transition: opacity 0.8s cubic-bezier(0.2, 0.3, 0.2, 0.8);
  }

  &:hover {
    background: rgba(35, 203, 208, 0.52);
    transform: translateY(-3px);
  }

  &:hover::before {
    opacity: 1;
  }

  p {
    color: white;
    font-size: 13px;
    font-weight: normal;
    line-height: 3px;
    text-transform: uppercase;
  }
`;

export const Icon = styled(Icons)`
  position: absolute;
  right: 1.5%;
  cursor: pointer;
`;
