import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from './styles';
import supportedLanguages from './languages';

function LangChooser({ value, onChange, languages }) {
  const { t } = useTranslation();

  return (
    <Select>
      <select value={value} onChange={onChange}>
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
      <p>{t('language')}</p>
    </Select>
  );
}

export { supportedLanguages, LangChooser as default };

LangChooser.propTypes = {
  languages: PropTypes.array.isRequired,
};

LangChooser.defaultProps = {
  languages: [],
};
