import styled from 'styled-components';
import { media } from '../../utils/media';

export const StyledButton = styled.button`
  font-size: 19px;
  color: white;
  border: none;
  padding: 18px 40px;
  font-weight: 400;
  background: linear-gradient(180deg, var(--color3) 0%, var(--color18) 100%);
  box-shadow: 0px 10px 20px var(--colorG);
  border-radius: 30px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  text-transform: uppercase;
  overflow:hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  &:hover {
    box-shadow: 0 20px 40px var(--colorI);
    transform: translateY(-3px);
    background: linear-gradient(180deg, var(--color8) 0.01%, var(--color18) 86.76%);
  }
  display: flex;
  flex-direction: row;

  ${media.phone`width: 100%`};
`