import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import Form from '../../components/Form';
import styled from 'styled-components';
import { media } from '../../utils/media';

export default function FormPage() {
  const { t: translate } = useTranslation();
  return (
    <Layout>
      <Container>
        <h1>{translate('form.title')}</h1>
        <Form />
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    color: var(--color1);
    text-align: center;
    ${media.phone`font-size: 20px`};
    ${media.phone`margin: 20px`};
  }
`;
