import styled from 'styled-components';
import { media } from '../../utils/media';

const width = '650px';
const height = '280px';

export const Container = styled.div`
  position: sticky;
  overflow: hidden;
  width: ${width};
  ${media.phone`width: 100%`};
  ${media.tablet`width: 100%`};
`;

export const Content = styled.div`
  width: ${width};
  ${media.phone`width: 100%`};
  ${media.tablet`width: 100%`};
  position: relative;
  z-index: -1;
  height: ${height};
`;

export const Indicator = styled.div`
  user-select: none;
  cursor: pointer;
  width: 20px;
  height: 4px;
  margin: 0 3px;
  background-color: ${(props) =>
    props.active ? 'var(--color3)' : 'var(--color16)'};
`;

export const Indicators = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 100;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
  position: absolute;
  width: 10%;
  height: 100%;
  cursor: pointer;
  ${(props) => (props.right ? 'left: 90%' : 'left: 0%')};
  ${media.phone`display: none`};
`;
