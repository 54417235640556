import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: auto;
    width: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
  }
`;

export function BurialInsurance(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 37 42" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3017 27.2029V41.7628L5.8146 33.1873C4.04742 32.1432 2.57666 30.6315 1.55816 28.8237C0.539656 27.012 0 24.9509 0 22.8626V8.29877L14.4871 16.8742C16.2543 17.9184 17.725 19.4301 18.7435 21.2379C19.762 23.0496 20.3017 25.1106 20.3017 27.2029ZM16.3113 34.6757V27.2029C16.3113 24.4132 14.8481 21.8145 12.4919 20.4197L3.99041 15.3859V22.8626C3.99041 25.6522 5.45356 28.2509 7.8098 29.6458L16.3113 34.6757Z" />
        <path
          opacity="0.29"
          d="M18.7435 21.2379C17.725 19.4262 16.2505 17.9184 14.4871 16.8742L0 8.29877L3.99041 15.3859L12.4919 20.4158C14.8481 21.8106 16.3113 24.4093 16.3113 27.199V28.6405H20.3017V27.199C20.3017 25.1106 19.762 23.0496 18.7435 21.2379Z"
        />
        <path d="M17.8961 12.9391C18.9146 11.1274 20.3891 9.61958 22.1525 8.57541L36.6396 0V14.5599C36.6396 16.6521 36.0999 18.7132 35.0814 20.521C34.0629 22.3327 32.5884 23.8405 30.825 24.8847L16.3379 33.464V18.9041C16.3379 16.8119 16.8737 14.7508 17.8961 12.9391ZM28.826 21.347C31.1822 19.9522 32.6454 17.3534 32.6454 14.5638V7.08709L24.1477 12.117C21.7915 13.5118 20.3283 16.1106 20.3283 18.9002V26.3769L28.826 21.347Z" />
        <path
          opacity="0.29"
          d="M20.332 18.6587C20.4156 15.9625 21.8598 13.469 24.1476 12.117L32.6491 7.08709V14.5638C32.6491 17.3534 31.1859 19.9522 28.8297 21.347L20.332 26.3769V31.0991L30.8211 24.8886C32.5883 23.8444 34.059 22.3327 35.0775 20.5249C36.0961 18.7132 36.6357 16.6521 36.6357 14.5638V0L22.1524 8.57542C21.5026 8.95724 20.332 18.6587 20.332 18.6587Z"
        />
      </svg>
    </Container>
  );
}

BurialInsurance.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

BurialInsurance.defaultProps = {
  color: '#26B6CE',
  size: 30,
};
