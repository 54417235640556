import React from 'react';
import Icon from '../Icons';
import makeCarousel from 'react-reveal/makeCarousel';
import {
  Container,
  Content,
  Indicators,
  Indicator,
  ArrowContainer,
} from './styles';

const Carousel = ({ position, total, handleClick, children }) => (
  <Container>
    <Content>
      {children}
      <ArrowContainer onClick={handleClick} data-position={position - 1}>
        <Icon name="arrow-left" />
      </ArrowContainer>
      <ArrowContainer onClick={handleClick} data-position={position + 1} right>
        <Icon name="arrow-right" />
      </ArrowContainer>
    </Content>
    <Indicators>
      {Array(...Array(total)).map((val, idx) => (
        <Indicator
          key={idx}
          active={idx === position}
          onClick={handleClick}
          data-position={idx}
        />
      ))}
    </Indicators>
  </Container>
);

export default makeCarousel(Carousel);
