import React from 'react';
import ScrollToTop from '../ScrollToTop';
import Copyright from './copyright';
import LeftSection from './left-section';
import RightSection from './right-section';
import { Header, Separator, Arrow } from './styles';

export default () => (
  <footer>
    <Header>
      <LeftSection />
      <Separator shouldRotate/>
      <RightSection />
      <Arrow>
        <ScrollToTop />
      </Arrow>
    </Header>
    <Copyright />
  </footer>
);
