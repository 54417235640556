import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '../Loader';
import { StyledButton } from './Button.styles';

const Button = (props) => (
  <StyledButton {...props}>
    {props.children}
    {props.loading && (
      <Loader
        size={8}
        loading={props.loading}
        color={'var(--color23)'}
        css={{ marginLeft: 10 }}
      />
    )}
  </StyledButton>
);

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  loading: false,
};

export { Button };
