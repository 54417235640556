import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import actions from '../../context/actions';
import { Translation } from 'react-i18next';
import { Context } from '../../context';
import { Banner, Button, Icon } from './Topbar.styles';

function Topbar() {
  const { state, dispatch } = useContext(Context);
  const { closeBanner } = actions(dispatch);

  return (
    <Fade top collapse appear delay={1000} when={state.app.bannerOpen}>
      <Banner>
        <Translation>{(t) => <p>{t('banner.title_0')}</p>}</Translation>
        <Button to={state.app.bannerLink || ''}>
          <Translation>{(t) => <p>{t('banner.view')}</p>}</Translation>
        </Button>
        <Icon
          name="close"
          size={17}
          color="var(--color17)"
          onClick={() => closeBanner()}
        />
      </Banner>
    </Fade>
  );
}

export { Topbar };
