import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title, GenderContainer, GenderText, RadioButton } from './styles';

export default function GenderInput({ gender, handleChange }) {
  const { t } = useTranslation();

  const isMale = () => gender === 'M';
  const isFemale = () => gender === 'F';

  return (
    <div>
      <Title>{t('form.gender.title')}</Title>
      <GenderContainer onChange={handleChange}>
        <RadioButton defaultChecked={isMale()} type="radio" value="M" name="gender" />
        <GenderText checked={isMale()}>{t('form.gender.male')}</GenderText>
        <RadioButton defaultChecked={isFemale()} type="radio" value="F" name="gender" id="female"/>
        <GenderText checked={isFemale()}>{t('form.gender.female')}</GenderText>
      </GenderContainer>
    </div>
  );
}