import styled from 'styled-components';
import { media } from '../../utils/media';

export const Container = styled.div`
  padding: 5% 18%;
  ${media.phone`padding: 0`};
  ${media.tablet`padding: 0`};
`;

export const Content = styled.div`
  margin: 7%;
  display: grid;
  grid-template-columns: 37% 63%;
  ${media.phone`grid-template-columns: 1fr`};
`;

export const ImgContainer = styled.div`
  img {
    position: absolute;
    width: 35%;
    height: 30%;
    z-index: -100;
  }

  #tablet {
    display: none;
  }

  #mobile {
    display: none;
  }

  ${media.tablet`
    #desktop {
      display: none;
    }
    #mobile {
      display: none;
    }
  `};
  
  ${media.tablet`
    #desktop {
      display: none;
    }
    #tablet {
      display: none;
    }
    #mobile {
      display: block;
      width: 35%;
      height: 45%;
    }
  `};
`;

export const Left = styled.div`
  h1 {
    color: var(--color1);
  }

  h3,
  p {
    color: var(--color4);
  }
`;
