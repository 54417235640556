import React from 'react';
import Slide from 'react-reveal/Slide';
import { useTranslation } from 'react-i18next';
import { Container, Content, ImgContainer, Left } from './styles';
import Carousel from '../Carousel';
import Review from '../Review';
import Separator from '../Separator';
import reviews from './reviews';

import desktop from '../../images/ellipse.desktop.png';
import tablet from '../../images/ellipse.tablet.png';
import mobile from '../../images/ellipse.mobile.png';

export default function Reviews() {
  const { t } = useTranslation();
  return (
    <Container>
      <Separator />
      <ImgContainer>
        <img id="desktop" src={desktop} alt="Elipse" />
        <img id="tablet" src={tablet} alt="Elipse" />
        <img id="mobile" src={mobile} alt="Elipse" />
      </ImgContainer>
      <Content>
        <Left>
          <h1>{t('homepage.reviews.title')}</h1>
          <h3>{t('homepage.reviews.subtitle')}</h3>
          <p>{t('homepage.reviews.based_on')}</p>
        </Left>
        <Carousel>
          {reviews.map((review, i) => (
            <Slide right key={i}>
              <Review {...review} />
            </Slide>
          ))}
        </Carousel>
      </Content>
      <Separator />
    </Container>
  );
}
