import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Separator, SeparatorGroup, Title, Images } from './styles';

export default function Showcase(props) {
  const { t: translate } = useTranslation();
  const { image, mobileImage, i18nTitle, hideTopLeft = false } = props;

  return (
    <Container>
      <SeparatorGroup>
        <Separator />
        <Title>{translate(i18nTitle)}</Title>
        {!hideTopLeft ? <Separator /> : <Separator transparent />}
      </SeparatorGroup>
      <Images>
        <img src={image} id="company-list" alt="Companies" />
        <img src={mobileImage} id="single-company" alt="Company" />
      </Images>
      <Separator />
    </Container>
  );
}

Showcase.propTypes = {
  image: PropTypes.node.isRequired,
  i18nTitle: PropTypes.string,
};
