import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../hooks';
import { Context } from '../../context';
import Loader from 'react-spinners/BeatLoader';
import actions from '../../context/actions';
import Separator from '../Separator';
import { Button } from '../Button';
import Select from './select';
import Input from './input';
import GenderInput from './gender-input';
import DayInput from './day-input';
import { StyledForm, Grid, Footer, LastRow, InputWithDetails } from './styles';
import { states, coverageOptions } from './data';

function Form(props) {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(false);
  const { state: { customer }, dispatch } = useContext(Context);
  const { saveUserData } = actions(dispatch);
  const { values, handleSubmit, handleChange, manuallyHandleChange } = useForm(
    customer,
    submit,
  );

  async function submit() {
    await setLoading(true);
    await saveUserData(values);
    await setLoading(false);
    await props.history.push('/thank-you');
  }

  function handleDate(day) {
    manuallyHandleChange({ name: 'dateofbirth', value: day });
  }

  // TODO: replace manuallyHandleChange() arguments from ({name, value}) to (name, value);
  function handleZipcode(event) {
    manuallyHandleChange({
      name: 'zipcode',
      value: event.target.value.replace(/[^0-9]/g, ''),
    });
  }

  const loader = () => (
    <Loader
      size={5}
      color={'#CFD4D3'}
      loading={loading}
      css={'margin-left: 5px'}
    />
  );

  const {
    name,
    lastname,
    gender,
    dateofbirth,
    email,
    phone,
    coverage,
    zipcode,
    street,
    city,
    state,
  } = values;

  console.log('dateOfBirth: ', dateofbirth);
  return (
    <StyledForm onSubmit={handleSubmit}>
      <Grid>
        <Input
          required={true}
          name="name"
          value={name}
          onChange={handleChange}
        />
        <Input
          required={true}
          name="lastname"
          value={lastname}
          onChange={handleChange}
        />
        <GenderInput gender={gender} handleChange={handleChange} />
        <DayInput
          name="dateofbirth"
          required={true}
          placeholder="MM/DD/YYYY"
          value={dateofbirth}
          onChange={handleDate}
        />
        <InputWithDetails>
          <Input
            required={true}
            name="email"
            value={email}
            onChange={handleChange}
          />
          <small>{t('homepage.footer.messages.copy_of_quote')}</small>
        </InputWithDetails>
        <InputWithDetails>
          <Input
            required={true}
            name="phone"
            placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
            value={phone}
            onChange={handleChange}
          />
          <small>{t('homepage.footer.messages.valid_phone')}</small>
        </InputWithDetails>
        <Select
          name="coverage"
          value={coverage}
          options={coverageOptions}
          onChange={handleChange}
        />
        <Input
          required={true}
          name="street"
          value={street}
          onChange={handleChange}
          dropdown
        />
        <LastRow>
          <Input
            zipcode
            name="zipcode"
            value={zipcode}
            onChange={handleZipcode}
          />
          <Input name="city" value={city} onChange={handleChange} />
          <Select
            name="state"
            value={state}
            options={states}
            onChange={handleChange}
          />
        </LastRow>
      </Grid>
      <Footer>
        <Button>
          {t('get_my_quotes')} {loading && loader()}
        </Button>
        <Separator />
        <p>{t(t('form.declaimer'))}</p>
      </Footer>
    </StyledForm>
  );
}

export default withRouter(Form);
