import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    width: auto;
    height: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
    transform: rotate(-180deg);
  }
`;

export function ArrowRight(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 51 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.58578 15.5858C3.80474 16.3668 3.80474 17.6332 4.58578 18.4142L17.3137 31.1421C18.0948 31.9232 19.3611 31.9232 20.1421 31.1421C20.9232 30.3611 20.9232 29.0948 20.1421 28.3137L8.82842 17L20.1421 5.68629C20.9232 4.90524 20.9232 3.63891 20.1421 2.85787C19.3611 2.07682 18.0948 2.07682 17.3137 2.85787L4.58578 15.5858ZM47 15L6 15V19L47 19L47 15Z" />
      </svg>
    </Container>
  );
}

ArrowRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

ArrowRight.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
