import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Topbar } from '../TopBar';
import Header from '../Header';
import Footer from '../Footer';
import './styles.css';

export default function Layout({ children }) {
  return (
    <Fragment>
      <Topbar />
      <Header />
      <div className="content">{children}</div>
      <Footer />
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
