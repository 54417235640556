import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../context';
import Layout from '../../components/Layout';

export default function ThankYou() {
  const { t: translate } = useTranslation();
  const { state: { customer } } = useContext(Context);
  return (
    <Layout>
      <div>
        <h1>
          {translate('tankyou.title')} {customer.name}!
        </h1>
      </div>
    </Layout>
  );
}
