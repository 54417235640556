export default {
  app: {
    modalOpen: false,
    bannerOpen: true,
    bannerLink: '/some-path',
    phoneNumber: '855-972-6848',
    businessAddress: '445 W. Libertiy St., Suite 215, Medina, OH 44256',
    email: 'quotes@burialinsurance.com',
  },
  formCompleted: false,
  contactForm: {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    content: '',
  },
  customer: {
    name: '',
    lastname: '',
    gender: 'M',
    dateofbirth: '',
    email: '',
    phone: '',
    coverage: '',
    zipcode: '',
    street: '',
    city: '',
    state: '',
  },
};
