import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    stroke: ${({ color }) => color};
    height: ${({ size }) => `${size}px`};
    width: auto;
  }
`;

export function Star(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 26 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            stroke={props.color}
            fill={props.empty ? 'none' : props.color}
            points="13 19.5 5.35879172 23.5172209 6.81813264 15.0086105 0.636265288 8.98277907 9.17939586 7.74138954 13 0 16.8206041 7.74138954 25.3637347 8.98277907 19.1818674 15.0086105 20.6412083 23.5172209"
          />
        </g>
      </svg>
    </Container>
  );
}

Star.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  empty: PropTypes.bool,
};

Star.defaultProps = {
  color: '#26b6ce',
  size: 30,
  empty: false,
};
