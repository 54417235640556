import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Title } from './styles';
import { FAQ } from '../FAQ';
import faqs from './faqs';

export default function FAQs() {
  const { t: translate } = useTranslation();
  return (
    <Container>
      <Title>{translate('homepage.faq_section.title')}</Title>
      {faqs.map((faq, i) => <FAQ key={i} {...faq} />)}
    </Container>
  );
}
