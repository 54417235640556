import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';

export default function Partners() {
  const { t: translate } = useTranslation();
  return (
    <Layout>
      <div>
        <h1>{translate('parner_companies')}</h1>
      </div>
    </Layout>
  );
}
