import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import Layout from '../../components/Layout';

const Styled404 = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: var(--color1);
`;

export default function NotFound() {
  return (
    <Layout>
      <Styled404>
        <h1>404</h1>
        <Translation>{(t) => <p>{t('not_found')}</p>}</Translation>
      </Styled404>
    </Layout>
  );
}
