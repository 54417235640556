import styled from 'styled-components';
import { media } from '../../utils/media';

export const Container = styled.div`
  dispaly: flex;
  justify-content: center;
  align-items: center;
  margin: 0 11%;
  ${media.phone`margin: 0 5%`};
  top: -10px;
  img {
    margin: 20px;
  }

  #single-company {
    display: none;
  }

  #company-list {
    width: 70%;
  }

  ${media.phone`
    #single-company {
      display: block;      
    }
    
    #company-list {
      display: none;
    }
  `};
`;

export const Images = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SeparatorGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Separator = styled.div`
  height: 0px;
  width: 100%;
  opacity: 0.2;
  border: 0.5px solid ${(props) => props.transparent ? 'transparent' : 'var(--color9)'};
  ${media.phone`border: 1px solid var(--color9)`};
`;

export const Title = styled.h3`
  text-align: center;
  margin: 0 3%;
  min-width: 20%;
  ${media.phone`min-width: 50%`};
  text-transform: uppercase;
  font-weight: 400;
  color: var(--color4);
  font-size: 17px;
`;
