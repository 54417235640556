import React from 'react';
import PropTypes from 'prop-types';

import { Star } from './Star';
import { Close } from './Close';
import { Email } from './Email';
import { Arrow } from './Arrow';
import { Phone } from './Phone';
import { Location } from './Location';
import { SendMail } from './SendMail';
import { Checkmark } from './Checkmark';
import { Telephone } from './Telephone';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { Exclamation } from './Exclamation';
import { BurialInsurance } from './BurialInsurance';

function Icon({ name, ...rest }) {
  return renderIcon(name, rest);
}

function renderIcon(name, rest) {
  switch (name) {
    case 'star':
      return <Star {...rest} />;
    case 'close':
      return <Close {...rest} />;
    case 'email':
      return <Email {...rest} />;
    case 'arrow':
      return <Arrow {...rest} />;
    case 'phone':
      return <Phone {...rest} />;
    case 'location':
      return <Location {...rest} />;
    case 'send-mail':
      return <SendMail {...rest} />;
    case 'checkmark':
      return <Checkmark {...rest} />;
    case 'telephone':
      return <Telephone {...rest} />;
    case 'arrow-left':
      return <ArrowLeft {...rest} />;
    case 'arrow-right':
      return <ArrowRight {...rest} />;
    case 'exclamation':
      return <Exclamation {...rest} />;
    case 'burial-insurance':
      return <BurialInsurance {...rest} />;
    default:
      return null;
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export { Icon };
