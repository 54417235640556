import React from 'react';
import Rating from 'react-rating';
import { Translation } from 'react-i18next';
import { Header, Container, Avatar, Footer, Left, Right } from './styles';
import { Star } from '../Icons';

function Review({ rating = 5, i18nTitle, i18nReview, avatar, i18nName, i18nLocation }) {
  const renderHeader = () => (
    <Header>
      <Rating
        readonly
        initialRating={rating}
        fullSymbol={<Star />}
        emptySymbol={<Star empty />}
      />
      <Translation>{(t) => <h4>{t(i18nTitle)}</h4>}</Translation>
    </Header>
  );

  return (
    <Container>
      {renderHeader()}
      <Translation>{(t) => <p>{t(i18nReview)}</p>}</Translation>
      <Footer>
        <Left>
          <img
            src={require('../../images/customer-gradient.png')}
            alt="Elipse"
            className="elipse"
          />
          <Avatar src={avatar} alt="Avatar" />
        </Left>
        <Right>
          <Translation>
            {(t) => <p className="name">-{t(i18nName)}</p>}
          </Translation>
          <Translation>
            {(t) => <p className="location">{t(i18nLocation)}</p>}
          </Translation>
        </Right>
      </Footer>
    </Container>
  );
}

export default Review;
