import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Right, Separator, LinksGroup } from './styles';

import Declaimer from './declaimer';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Right>
      <LinksGroup>
        <Link to="/partners">{t('homepage.footer.partners')}</Link>
        <Separator margin height="18px" />
        <Link to="/contact">{t('homepage.footer.contact')}</Link>
      </LinksGroup>
      <Declaimer />
    </Right>
  );
}
