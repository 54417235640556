import styled, { css } from 'styled-components';

export const StyledLoader = styled.div`
  ${(props) =>
    props.stretch &&
    css`
      min-height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: calc(10px + 2vmin);
    `};
`;
