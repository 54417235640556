import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: auto;
    width: ${({ size }) => `${size}px`};
    fill: ${({ color }) => color};
  }
`;

export function Close(props) {
  return (
    <Container {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
        <path d="M14.2572 12.52L24.6406 2.12C25.1198 1.64 25.1198 0.84 24.6406 0.36C24.1613 -0.12 23.3626 -0.12 22.8834 0.36L12.5 10.92L2.11661 0.36C1.63738 -0.12 0.838658 -0.12 0.359425 0.36C-0.119808 0.84 -0.119808 1.64 0.359425 2.12L10.7428 12.52L0.359425 22.92C-0.119808 23.4 -0.119808 24.2 0.359425 24.68C0.519169 24.84 0.838658 25 1.15815 25C1.47764 25 1.79712 24.84 1.95687 24.68L12.3403 14.28L22.7236 24.68C22.8834 24.84 23.2029 25 23.5224 25C23.8419 25 24.1613 24.84 24.3211 24.68C24.8003 24.2 24.8003 23.4 24.3211 22.92L14.2572 12.52Z" />
      </svg>
    </Container>
  );
}

Close.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Close.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
