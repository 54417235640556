import axios from 'axios';

export default class Service {
  static get url() {
    return process.env.REACT_APP_API;
  }

  static saveUserData(data) {
    return axios
      .post(`${this.url}/customers/create`, data)
      .then((res) => Promise.resolve(data))
      .catch((err) => Promise.reject(err));
  }

  static validateZip({ zipcode }) {
    return axios
      .post(`${this.url}/zipcode`, { zipcode })
      .then((res) => Promise.resolve({ ...res.data, zipcode }))
      .catch((err) => Promise.reject(err));
  }

  static contact(data) {
    return axios
      .post(`${this.url}/messages/create`, data)
      .then((res) => Promise.resolve(data))
      .catch((err) => Promise.reject(err));
  }
}
