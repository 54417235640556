import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: auto;
    width: ${({ size }) => `${size}px`};
    stroke: ${({ color }) => color};
  }
`;

export function Phone(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="nonzero" strokeWidth="1">
          <path d="M25.8672,27.55343 L25.8637,27.55713 C24.9088,28.59413 23.7407,29.09723 22.278,29.09723 C22.1482,29.09723 22.0235,29.09183 21.8906,29.08603 L21.8775,29.08543 C19.6722,28.94253 17.6896,28.11033 16.1966,27.40283 C12.2831,25.50583 8.85419,22.82503 6.0052,19.41943 C3.65696,16.59793 2.07869,13.96013 1.03293,11.12163 C0.351994,9.28787 0.0927002,7.78037 0.204955,6.36771 C0.298662,5.24977 0.748386,4.2859 1.54485,3.48944 L3.79119,1.24309 C4.41466,0.649189 5.11762,0.34723 5.84311,0.34723 C6.57524,0.34723 7.27196,0.649667 7.87081,1.23688 C8.27939,1.6155 8.69207,2.03411 9.08848,2.44253 L9.10531,2.45985 C9.30362,2.66401 9.49743,2.86352 9.70271,3.0688 L11.4934,4.85952 C12.13,5.4902 12.4543,6.21082 12.4543,6.93441 C12.4543,7.65756 12.1243,8.37842 11.4934,9.00931 C11.307,9.19576 11.1202,9.38253 10.9393,9.56944 C10.4287,10.09205 9.90235,10.62433 9.34071,11.13223 L9.27426,11.19233 L9.30981,11.27453 C9.71811,12.21913 10.3018,13.14193 11.1653,14.23483 C12.8741,16.33023 14.6626,17.96223 16.6346,19.21443 C16.8389,19.34443 17.0665,19.46113 17.3067,19.58123 C17.4995,19.67763 17.7005,19.77823 17.8951,19.89023 L17.9781,19.93803 L18.0459,19.87033 L20.1971,17.71903 C20.809,17.10723 21.5177,16.78813 22.248,16.78813 C22.9782,16.78813 23.68,17.10693 24.2731,17.72333 L24.2748,17.72503 L27.8863,21.33653 C28.5125,21.96263 28.8187,22.67873 28.8202,23.39293 C28.8216,24.10733 28.5179,24.83453 27.8965,25.48213 C27.6218,25.76883 27.3348,26.04983 27.053,26.31963 C27.0235,26.34823 26.9947,26.37623 26.966,26.40403 C26.5835,26.77503 26.2125,27.13483 25.8834,27.53203 L25.8743,27.54303 L25.8672,27.55343 Z" />
        </g>
      </svg>
    </Container>
  );
}

Phone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Phone.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
