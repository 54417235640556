import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Title, SubTitle, GridContainer, GridCell } from './styles';

export default function HowItWorks() {
  const { t: translate } = useTranslation();
  return (
    <Fragment>
      <Header>
        <Title>{translate('homepage.how_it_works.title')}</Title>
        <SubTitle>{translate('homepage.how_it_works.subtitle')}</SubTitle>
      </Header>
      <GridContainer>
        <GridCell>
          <img src={require('../../images/customer.png')} alt="customer" />
          <h3>{translate('homepage.how_it_works.option_0.title')}</h3>
          <p>{translate('homepage.how_it_works.option_0.subtitle')}</p>
        </GridCell>
        <GridCell>
          <img src={require('../../images/heart.png')} alt="heart" />
          <h3>{translate('homepage.how_it_works.option_1.title')}</h3>
          <p>{translate('homepage.how_it_works.option_1.subtitle')}</p>
        </GridCell>
        <GridCell>
          <img src={require('../../images/money.png')} alt="money" />
          <h3>{translate('homepage.how_it_works.option_2.title')}</h3>
          <p>{translate('homepage.how_it_works.option_2.subtitle')}</p>
        </GridCell>
      </GridContainer>
    </Fragment>
  );
}
