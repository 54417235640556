import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import formatDate from 'date-fns/format';
import isValidDate from 'date-fns/isValid';
import { useTranslation } from 'react-i18next';
import { Title, DayPicker } from './styles';

import 'react-day-picker/lib/style.css';

const classes = DayPickerInput.defaultProps.classNames;

export const DayPickerInputWrapper = ({ className = '', ...rest }) => {
  const container = `${classes.container} ${className}`;
  return <DayPickerInput classNames={{ ...classes, container }} {...rest} />;
};

export default function Input(props) {
  const { t } = useTranslation();
  const title = t(`form.${props.name}`);

  const onChange = (day) => {
    if (isValidDate(day)) {
      props.onChange(formatDate(day, 'MM/dd/yyyy'));
    }
  };

  // TODO: add parseDate prop to DayPicker
  return (
    <label>
      <Title required={props.required}>{title}</Title>
      <DayPicker {...props} onDayChange={onChange} />
    </label>
  );
}
