import React from 'react';
import { Button } from './styles';
import { Arrow } from '../Icons';

export default () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button onClick={scrollToTop}>
      <Arrow />
    </Button>
  );
};
