import React from 'react';
import { Translation } from 'react-i18next';
import { Header, Logo, HeaderGroup } from './styles';
import PhoneButton from '../PhoneButton';
import headerLogo from '../../images/header-logo.svg';

export default function HeaderComponent() {
  return (
    <Header>
      <a href="/">
        <Logo src={headerLogo} />
      </a>
      <HeaderGroup>
        <Translation>{(t) => <h2>{t('request_quote')}</h2>}</Translation>
        <PhoneButton />
      </HeaderGroup>
    </Header>
  );
}
