import styled, { css } from 'styled-components';
import { DayPickerInputWrapper } from './day-input';
import { media } from '../../utils/media';

export const StyledForm = styled.form`
  background-color: var(--color23);
  border: 1px solid var(--color9);
  box-sizing: border-box;
  border-radius: 4px;
  width: calc(100vw - 60vw);
  ${media.phone`width: calc(100vw - 5vw)`};
  ${media.tablet`width: calc(100vw - 10vw)`};
  margin-bottom: 40px;
  
  @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
    width: calc(100vw - 5vw);
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${media.phone`grid-template-columns: 1fr`};
  grid-gap: 15px;
  margin: 20px 15%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  button {
    margin-bottom: 20px;
  }
  p {
    font-size: 10px;
    font-weight: 100;
    margin: 2em 4%;
    line-height: 12px;
    opacity: 0.8;
  }
`;

export const Title = styled.p`
  color: var(--color4);
  text-transform: uppercase;
  ${(props) =>
    props.required &&
    css`
      &:after {
        content: " *";
        color: var(--color15);
      }
    `};
`;

export const Input = styled.input`
  background: var(--color13);
  border: 1px solid var(--color9);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 300;
  color: var(--color1);
  outline: none;

  &::placeholder {
    border-color: var(--color3);
    text-transform: capitalize;
    color: var(--color24);
  }

  &:active: {
    border-color: var(--color25);
  }

  ${({ empty }) =>
    empty &&
    css`
      border-color: var(--color3) !important;
      color: var(--color4);
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01),
        0px 0px 8px rgba(0, 0, 0, 0.2);
    `};
`;

export const DayPicker = styled(DayPickerInputWrapper)`
  input {
    background: var(--color13);
    border: 1px solid var(--color9);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 45px;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 300;
    color: var(--color1);
    outline: none;

    &::placeholder {
        border-color: var(--color3);
        text-transform: capitalize;
        color: var(--color24);
    }

    &:active: {
        border-color: var(--color25);
    }
  }
`;

export const InputWithDetails = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  small {
    align-self: center;
    opacity: 0.7;
    font-size: 10px;
    margin-top: 5px;
  }
`;

export const DropDown = styled.select`
  background: var(--color13);
  border: 1px solid var(--color9);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  outline: none;

  /* https://stackoverflow.com/a/56560979/7602110 */
  display: inline-block;
  padding: 0.5em 2em 0.5em 0.5em;
  font-size: 14px;
  font-weight: 300;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1.4em, right 10px top 1.4em;
  background-size: 5px 5px, 5px 5px;
  color: ${(props) => (props.isEmpty ? 'var(--color24)' : 'var(--color1)')};
`;

export const LastRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-start: 1;
  grid-column-end: 3;
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-start: 1;
    grid-column-end: 2;
  `};
  grid-gap: 1em;
`;

export const GenderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  #female {
    margin-left: 20px;
  }
`;

export const GenderText = styled.p`
  color: var(--${(props) => (props.checked ? 'color4' : 'color24')});
`;

export const RadioButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: var(--color13);
  color: #666;
  top: 10px;
  height: 25px;
  width: 25px;
  border: 1.25px solid var(--color9);
  box-sizing: border-box;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;

  &:checked::before {
    font: 25px 'Open Sans', sans-serif;
  }

  &:checked {
    border: 3px solid var(--color13);
    background-color: var(--color3);
    box-shadow: 0px 1px 4px rgba(37, 46, 67, 0.36);
  }
`;
