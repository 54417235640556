export default [
  {
    rating: 5,
    i18nTitle: 'homepage.reviews.list_of_reviews.customer_0.title',
    i18nReview: 'homepage.reviews.list_of_reviews.customer_0.content',
    avatar: require('../../images/customer_0.png'),
    i18nName: 'homepage.reviews.list_of_reviews.customer_0.name',
    i18nLocation: 'homepage.reviews.list_of_reviews.customer_0.location',
  },
  {
    rating: 4.5,
    i18nTitle: 'Awesome Job',
    i18nReview: 'homepage.reviews.list_of_reviews.customer_0.content',
    avatar: 'https://randomuser.me/api/portraits/men/46.jpg',
    i18nName: 'Mark',
    i18nLocation: 'Miami, FL',
  },
  {
    rating: 2,
    i18nTitle: 'Awesome Job',
    i18nReview: 'homepage.reviews.list_of_reviews.customer_0.content',
    avatar: 'https://randomuser.me/api/portraits/men/17.jpg',
    i18nName: 'James',
    i18nLocation: 'Seattle, WA',
  },
  {
    rating: 3.5,
    i18nTitle: 'Awesome Job',
    i18nReview: 'homepage.reviews.list_of_reviews.customer_0.content',
    avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    i18nName: 'Elizabeth',
    i18nLocation: 'Los Angeles, CA',
  },
];
