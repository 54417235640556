import styled from 'styled-components';
import { media } from '../../utils/media';

export const Container = styled.div`
  position: block;
  margin: 20px 10%;
  ${media.phone`margin: 0`} p {
    color: var(--color4);
    font-weight: 200;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${media.phone`flex-direction: column`};
  ${media.phone`align-items: start`};
  h4 {
    color: var(--color4);
    font-size: 20px;
    margin: 0 0 0 15px;
    ${media.phone`margin: 0`};
  }
`;

export const Avatar = styled.img`
  width: 80px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  margin: 15px 0 0 15px;
`;

export const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  ${media.phone`grid-template-columns: 1fr 2fr`};
`;

export const Left = styled.div`
  img {
    user-select: none;
  }
  .elipse {
    position: absolute;
    overflow: hidden;
    z-index: -1px;
    object-fit: contain;
    width: 75px;
  }
`;

export const Right = styled.div`
  .name {
    color: var(--color5);
    font-weight: 700;
  }
  .location {
    color: var(--color10);
    font-weight: 300;
  }
`;
