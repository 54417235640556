import styled from 'styled-components';

export const Select = styled.div`
  display: flex;
  align-items: center;
  
  select {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    padding: 0 15px;
  }
  p {
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 15px;
  }
`;
