import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    height: ${({ size }) => `${size}px`};
    fill: ${({ color }) => color};
    width: auto;
  }
`;

export function Arrow(props) {
  return (
    <Container {...props}>
      <svg viewBox="0 0 31 44" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9127 1.08481C16.1317 0.303759 14.8654 0.303759 14.0843 1.08481L1.3564 13.8127C0.575352 14.5938 0.575352 15.8601 1.3564 16.6412C2.13745 17.4222 3.40378 17.4222 4.18483 16.6412L15.4985 5.32745L26.8122 16.6412C27.5933 17.4222 28.8596 17.4222 29.6407 16.6412C30.4217 15.8601 30.4217 14.5938 29.6407 13.8127L16.9127 1.08481ZM17.4985 43.499L17.4985 2.49902H13.4985L13.4985 43.499L17.4985 43.499Z" />
      </svg>
    </Container>
  );
}

Arrow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

Arrow.defaultProps = {
  color: '#26b6ce',
  size: 30,
};
