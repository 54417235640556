import styled from 'styled-components';
import { media } from '../../utils/media';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5%;
`;

export const Title = styled.h1`
  color: var(--color1);
  font-weight: 500;
  font-size: 50px;
  ${media.phone`font-size: 35px`};
  ${media.tablet`font-size: 40px`}
`;

export const SubTitle = styled.h2`
  color: var(--color4);
  font-weight: 400;
  font-size: 20px;
  max-width: 35%;
  ${media.phone`max-width: 90%`};
  ${media.phone`font-size: 16px`};
  ${media.tablet`max-width: 80%`};
  text-align: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  ${media.phone`grid-template-columns: auto`};
  grid-gap: 8px;
  ${media.phone`grid-gap: 5px`};
  padding: 0 25%;
  ${media.phone`padding: 0 10%`};
  ${media.tablet`padding: 0 10% 30px 10%`};
`;

export const GridCell = styled.div`
  display: grid;
  grid-template-rows: auto 20% 40%;
  grid-gap: 5px;
  h3, p {
    color: var(--color4);
  }
  p {
    font-size: 15px;
  }

  img, h3, p {
    justify-self: center;
  }
`;
