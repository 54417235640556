export default [
  {
    i18nTitle: 'homepage.faq_section.faq_0.title',
    i18nContent: 'homepage.faq_section.faq_0.content',
    image: require('../../images/faq_1.png'),
    i18nImageSubTitle: 'homepage.faq_section.faq_0.small_title',
    right: true,
  },
  {
    i18nTitle: 'homepage.faq_section.faq_1.title',
    i18nContent: 'homepage.faq_section.faq_1.content',
    image: require('../../images/faq_2.png'),
    i18nImageSubTitle: 'homepage.faq_section.faq_1.small_title',
    right: false,
  },
  {
    i18nTitle: 'homepage.faq_section.faq_2.title',
    i18nContent: 'homepage.faq_section.faq_2.content',
    image: require('../../images/faq_3.png'),
    i18nImageSubTitle: 'homepage.faq_section.faq_2.small_title',
    right: true,
  },
];
