// import React, { useState, useContext, useEffect } from 'react';
// import Layout from '../../components/Layout';
// import Hero from '../../components/Hero';
// import Showcase from '../../components/Showcase';
// import HowItWorks from '../../components/HowItWorks';
// import CallToAction from '../../components/CallToAction';
// import FAQs from '../../components/FAQs';
// import Reviews from '../../components/Reviews';
// // import Modal from '../../components/Modal';
// import { Context } from '../../context';
// import actions from '../../context/actions';

// export default function Index({ history }) {
//   const [ zipcode, setZipcode ] = useState('');
//   const [ loading, setLoading ] = useState(false);
//   const { state, dispatch } = useContext(Context);
//   const { openModal, validateZip } = actions(dispatch);

//   useEffect(() => {
//     const timer = setTimeout(() => openModal(), 5000);
//     return () => clearTimeout(timer);
//     // eslint-disable-next-line
//   }, []);

//   const onZipcodeChange = (e) => {
//     setZipcode(e.target.value.replace(/[^0-9]/g, ''));
//   };

//   // TODO: Handle when the zipcodee is not valid.
//   const handleSubmit = async () => {
//     await setLoading(true);
//     await validateZip({ zipcode });
//     await setLoading(false);
//     await history.push('/form');
//   };

//   return (
//     <Layout>
//       <Hero
//         zipcode={zipcode}
//         onZipcodeChange={onZipcodeChange}
//         onGetQuote={handleSubmit}
//         loading={loading}
//       />
//       <Showcase
//         image={require('../../images/trusted.companies.png')}
//         mobileImage={require('../../images/bluecroos.png')}
//         i18nTitle="homepage.hero.trusted_by"
//         hideTopLeft
//       />
//       <HowItWorks />
//       <Reviews />
//       <FAQs />
//       <Showcase
//         image={require('../../images/featured.companies.png')}
//         mobileImage={require('../../images/wallstreet.png')}
//         i18nTitle="featured_in"
//       />
//       <CallToAction
//         zipcode={zipcode}
//         onZipcodeChange={onZipcodeChange}
//         onGetQuote={handleSubmit}
//         loading={loading}
//       />
//       {/* {state.app.modalOpen && <Modal />} */}
//     </Layout>
//   );
// }



import React, { Component } from 'react'
import Layout from '../../components/Layout';
// import Hero from '../../components/Hero';
import Showcase from '../../components/Showcase';
import HowItWorks from '../../components/HowItWorks';
// import CallToAction from '../../components/CallToAction';
import FAQs from '../../components/FAQs';
import Reviews from '../../components/Reviews';
// import Modal from '../../components/Modal';

export default class Home extends Component {
  render() {
    return (
      <Layout>
      {/* <Hero
        zipcode={zipcode}
        onZipcodeChange={onZipcodeChange}
        onGetQuote={handleSubmit}
        loading={loading}
      /> */}
      <Showcase
        image={require('../../images/trusted.companies.png')}
        mobileImage={require('../../images/bluecroos.png')}
        i18nTitle="homepage.hero.trusted_by"
        hideTopLeft
      />
      <HowItWorks />
      <Reviews />
      <FAQs />
      <Showcase
        image={require('../../images/featured.companies.png')}
        mobileImage={require('../../images/wallstreet.png')}
        i18nTitle="featured_in"
      />
      {/* <CallToAction
        zipcode={zipcode}
        onZipcodeChange={onZipcodeChange}
        onGetQuote={handleSubmit}
        loading={loading}
      /> */}
      {/* {state.app.modalOpen && <Modal />} */}
    </Layout>
    )
  }
}
