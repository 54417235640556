import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, ImageContainer, TextContainer } from './FAQ.styles';

function FAQ({ title, image, content, imageSubTitle, right }) {
  const { t: translate } = useTranslation();

  const renderImage = () => (
    <ImageContainer>
      <img src={image} alt="FAQ" />
      <p className="image-subtitle">{translate(imageSubTitle)}</p>
    </ImageContainer>
  );

  return (
    <Container right={right}>
      {!right && renderImage()}
      <TextContainer>
        <h3>{translate(title)}</h3>
        <p>{translate(content)}</p>
      </TextContainer>
      {!!right && renderImage()}
    </Container>
  );
}

FAQ.propTypes = {
  title: PropTypes.string,
  imageSubTitle: PropTypes.string,
  content: PropTypes.string,
  subTitle: PropTypes.string,
  right: PropTypes.bool,
};

FAQ.defaultProps = {
  right: false,
};

export { FAQ };
