import React from 'react';
import Icon from '../Icons';
import { Consumer, connect } from '../../context';
import { Container } from './styles';

function PhoneNumber() {
  return (
    <Consumer>
      {(context) => (
        <Container>
          <Icon name="telephone" color="white" />
          <h2>{context.state.app.phoneNumber}</h2>
        </Container>
      )}
    </Consumer>
  );
}

export default connect(PhoneNumber);
