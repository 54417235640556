import * as types from './types';
import Services from './services';

export default (dispatch) => ({
  reset: () => {
    return dispatch({
      type: types.RESET,
    });
  },

  openModal: () => {
    return dispatch({
      type: types.OPEN_MODAL,
    });
  },

  closeModal: () => {
    return dispatch({
      type: types.CLOSE_MODAL,
    });
  },

  openBanner: () => {
    return dispatch({
      type: types.OPEN_BANNER,
    });
  },

  closeBanner: () => {
    return dispatch({
      type: types.CLOSE_BANNER,
    });
  },

  resetForm: () => {
    return dispatch({
      type: types.RESET_FORM,
    });
  },

  saveUserData: (data) => {
    return Services.saveUserData(data)
      .then((res) => {
        return dispatch({ type: types.SAVE_USER_DATA_SUCCESS, data: res });
      })
      .catch((err) => {
        return dispatch({ type: types.SAVE_USER_DATA_FAILD });
      });
  },

  validateZip: ({ zipcode }) => {
    return Services.validateZip({ zipcode })
      .then((res) => {
        return dispatch({
          type: types.VALIDATE_ZIP_CODE_SUCCESS,
          data: { ...res.data, zipcode: res.zipcode },
        });
      })
      .catch((err) => {
        return dispatch({ type: types.VALIDATE_ZIP_CODE_FAILD });
      });
  },

  contact: (data) => {
    return Services.contact(data)
    .then((res) => {
    return dispatch({ type: types.CONTACT_SUCCESS, data: res });
  })
  .catch((err) => {
    return dispatch({ type: types.CONTACT_FAILD });
  });
},
});
