import React from 'react';
import PropTypes from 'prop-types';
import BeatLoader from 'react-spinners/BeatLoader';
import { StyledLoader } from './Loader.styles';

const Loader = ({ size, color, loading, stretch, css }) => (
  <StyledLoader stretch={stretch}>
    <BeatLoader size={size} loading={loading} color={color} css={css} />
  </StyledLoader>
);

Loader.propTypes = {
  size: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string,
  stretch: PropTypes.bool,
  loading: PropTypes.bool,
  css: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
};

Loader.defaultProps = {
  size: 10,
  color: 'white',
  stretch: false,
  loading: true,
};

export { Loader };
