import styled from 'styled-components';
import { media } from '../../utils/media';

export const Header = styled.header`
  width: 100%;
  padding: 30px 0;
  background-color: var(--color13);
  box-shadow: 0px 2px 4px var(--colorF);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0.3% 8% 0.3% 10%;
  ${media.tablet`padding: 0.3% 1%;`};
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  h2 {
    color: var(--color1);
    font-size: 20px;
    margin-right: 5px;
    ${media.tablet`display: none;`};
  }
`;

export const Logo = styled.img`
  height: auto;
  width: 270px;
  ${media.phone`width: 170px`};
`;
