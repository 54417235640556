import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../context';
import { FooterOption } from './styles';
import { Left, OptionsContainer } from './styles';
import Icon from '../Icons';

export default () => {
  const { t } = useTranslation();
  const { state } = useContext(Context);

  return (
    <Left>
      <a href="/">
        <img src={require('../../images/footer.logo.png')} alt="Logo" />
      </a>
      <OptionsContainer>
        <FooterOption>
          <Icon name="location" color="var(--color9)" />
          <p>{state.app.businessAddress}</p>
        </FooterOption>
        <FooterOption>
          <Icon name="phone" color="var(--color9)" />
          <p>
            <b>{t('homepage.footer.phone_24h')}</b>
            <br />
            {state.app.phoneNumber}
          </p>
        </FooterOption>
        <FooterOption>
          <Icon name="email" color="var(--color9)" />
          <p>
            <b>{t('homepage.footer.have_questions')}</b>
            <br />
            {state.app.email}
          </p>
        </FooterOption>
      </OptionsContainer>
    </Left>
  );
};
