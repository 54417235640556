import { useState } from 'react';

const useForm = (initialState, callback ) => {
  const [ values, setValues ] = useState(initialState);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    callback();
  };

  const handleChange = (event) => {
    event.persist();

    const { name, value } = event.target;
    setValues((values) => ({ ...values, [name]: value }));
  };

  const manuallyHandleChange = ({ name, value }) => {
    setValues((values) => ({ ...values, [name]: value }));
  };
  
  return {
    manuallyHandleChange,
    handleChange,
    handleSubmit,
    values,
  };
};

export default useForm;
