import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { StyledDeclaimer } from './styles';

export default () => (
  <StyledDeclaimer>
    <Trans i18nKey="homepage.footer.declaimer">
      BurialInsurance.com is not an insurance or operating company but connects individuals with insurance providers. Products and services are provided exclusively by the providers. Descriptions are for informational purposes only and subject to change. Insurance plans may not be available in all states. For a complete description, please call 1-866-324-8006 to determine eligibility and to request a copy of the applicable policy. BurialInsurance.com is not affiliated with any insurance plan nor does it represent or endorse any plan. BurialInsurance.com is not affiliated with or endorsed by the United States government or the federal Medicare program . By using this site, you acknowledge that you have read and agree to the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
    </Trans>
  </StyledDeclaimer>
);