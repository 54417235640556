import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-conter: center;
  align-items: center;
  border: 1px solid var(--color19);
  box-sizing: border-box;
  border-radius: 100px;
  h2 {
    margin: 0 10px;
  }

  &:hover {
    border: 1px solid var(--color22);
    h2 {
      color: var(--color22);
    }
  }
`;
